const routes = [
    {
        type: 'route',
        path: '/',
        routes: [],
        props: {
            text: 'Home',
        },
    }
];

export const navItemsData = routes.map( ( route ) => {
    return {
        path: route.path,
        text: route.props.text,
        createBtn: { ...route.props.createBtn },
    };
} );

export const homeTilesData = routes.map( ( route ) => {
    return {
        path: route.path,
        name: route.props.text,
        description: route.props.description,
        icon: route.props.icon,
    };
} );

routes[ 0 ].routes.push( {
    type: 'application',
    name: '@pulselive/homepage',
    props: { applicationRoutes: homeTilesData },
} );

export default routes;
