import {
    constructRoutes,
    constructApplications,
    constructLayoutEngine,
} from 'single-spa-layout';
import {
    registerApplication,
    start,
    getAppStatus,
    getMountedApps,
    getAppNames,
} from 'single-spa';
import { pulseliveSSO, READY_EVENT } from '@pulselive/keycloak-sdk-wrapper';
import { ReactComponent as applicationLogo } from './assets/pulselive-portal-logo.svg';

import applicationRoutes from './routes';

pulseliveSSO.initiated ?
    startWhenAuthed() :
    document.addEventListener( READY_EVENT, startWhenAuthed );

/**
 * Start When Authenticated
 *
 * When the user is authenticated via the keycloak SDK, start the app.
 */
function startWhenAuthed() {
    if ( pulseliveSSO.keycloak.authenticated ) {
        startApp();
        return;
    }

    // Redirects user to login SSO page
    pulseliveSSO.login();
}

/**
 * Start App
 *
 * SingleSpa bootstrapping code. Initialises browser routing and appropriate
 * apps.
 */
function startApp() {
    const routes = constructRoutes( {
        routes: [
            {
                type: 'application',
                name: '@pulselive/auth-ui',
                props: {
                    applicationRoutes: [],
                    applicationLogo
                },
            },
            ...applicationRoutes,
        ],
    } );

    const applications = constructApplications( {
        routes,
        loadApp: ( { name } ) => System.import( name ),
    } );

    const layoutEngine = constructLayoutEngine( {
        routes,
        applications,
        active: false,
    } );

    layoutEngine.activate();

    applications.forEach( registerApplication );

    start( {
        urlRerouteOnly: true,
    } );

    if ( 'serviceWorker' in navigator && !process.env.dev ) {
        navigator.serviceWorker.register( '/service-worker.js' );
    }

    /**
     * Export some singleSpa utility functions to the window.
     * This facilitates E2E unit tests for the app.
     */
    window.getAppStatus = getAppStatus;
    window.getAppNames = getAppNames;
    window.getMountedApps = getMountedApps;
}
